$(document).ready(function () {
  window.allCountriesStatesCities = {};

  let $dropDownCountry;
  let $dropDownState;
  let $dropDownCity;

  function initializeData() {
    window.APICaller.getCountriesStatesCities().then(function(data) {
      window.allCountriesStatesCities = data;

      populateAccountRegistrationCountries(data ? data.countries : []);
    });
  }

  function populateAccountRegistrationCountries(countries) {
    countries = countries || [];

    let countryDataForDropdown = [];
    countries.forEach(function (country) {
      countryDataForDropdown.push({
        value: country.countryName,
        text: country.countryName,
        related: country.countryCode,
      });
    });

    // Create country dropdown with country data
    $dropDownCountry = window.populateDropdown(
      countryDataForDropdown,
      '.js-account-registration-country-dropdown',
      'accountRegistrationCountryTemplate');

    // Create state dropdown with empty data
    $dropDownState = window.populateDropdown(
      [],
      '.js-account-registration-state-dropdown',
      'accountRegistrationStateTemplate');

    // Create city dropdown with empty data
    $dropDownCity = window.populateDropdown(
      [],
      '.js-account-registration-city-dropdown',
      'accountRegistrationCityTemplate');

    $dropDownCountry.on('selectedItem.drpAutoComplete', handleSelectedCountry);

    popuateAccountRegistrationPhoneCode(countries);
  }

  function populateAccountRegistrationStates(states) {
    states = states || [];

    let dataTransform = [];
    states.forEach(function (state) {
      dataTransform.push({
        value: state.stateName,
        text: state.stateName,
        related: state.stateCode,
      });
    });

    $dropDownState = window.populateDropdown(
      dataTransform,
      '.js-account-registration-state-dropdown',
      'accountRegistrationStateTemplate');

    $dropDownState.on('selectedItem.drpAutoComplete', handleSelectedState);

    reset($dropDownState);
  }

  function populateAccountRegistrationCities(cities) {
    cities = cities || [];

    let dataTransform = [];
    cities.forEach(function (city) {
      dataTransform.push({
        value: city.cityName,
        text: city.cityName,
        related: city.stateCode,
      });
    });

    $dropDownCity = window.populateDropdown(
      dataTransform,
      '.js-account-registration-city-dropdown',
      'accountRegistrationCityTemplate');

    reset($dropDownCity);
  }

  function popuateAccountRegistrationPhoneCode(phoneCodes) {
    if (!phoneCodes || !phoneCodes.length) {
      return;
    }

    const dataTransform = [];
    phoneCodes.forEach(function (country) {
      dataTransform.push({
        value: `${country.countryName} (+${country.phoneCode})`,
        text: `${country.countryName} (+${country.phoneCode})`,
        related: country.countryCode,
      });
    });

    window.populateDropdown(
      dataTransform,
      '.js-drp-phone-code',
      '',
      '#phoneCode .dropdown-menu');
  }

  function handleSelectedCountry(e, target) {
    const countryCode = $(target).data('related');

    const countrySelected = window.allCountriesStatesCities.countries.find(country => country.countryCode === countryCode);
    if (countrySelected) {
      const phoneCodeFormat = `${countrySelected.countryName} (+${countrySelected.phoneCode})`;
      window.updateDataField($('.js-account-registration-phone-code'), phoneCodeFormat);
    }

    const states = window.allCountriesStatesCities.states.filter(state => state.countryCode === countryCode);
    const cities = window.allCountriesStatesCities.cities.filter(city => city.countryCode === countryCode);

    populateAccountRegistrationStates(states);
    populateAccountRegistrationCities(cities);
  }

  function handleSelectedState(e, target) {
    const stateCode = $(target).data('related');

    const cities = window.allCountriesStatesCities.cities.filter(city => city.stateCode === stateCode);

    populateAccountRegistrationCities(cities);
  }

  function reset($drp) {
    const isDisabled = !$drp.find('ul > li.js-drp-item').length;

    $drp.find('input[type="text"]')
      .val('').setCustomValidity('')
      .trigger('resetvalidation')
      .prop('disabled', isDisabled);

    $drp.find('.js-float-label').removeClass('floating-label-modify');
  }

  initializeData();

  // export
  window.populateAccountRegistrationCities = populateAccountRegistrationCities;
  window.populateAccountRegistrationStates = populateAccountRegistrationStates;
  window.populateAccountRegistrationCountries = populateAccountRegistrationCountries;
});
